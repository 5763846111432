import { PanelPlugin } from '@grafana/data';
import { PanelOptions } from './types';
import { LLMPanel } from './components/LLMPanel';

export const plugin = new PanelPlugin<PanelOptions>(LLMPanel).setPanelOptions((builder) => {
  return builder
  // .addTextInput({
  //   path: 'apiKey',
  //   name: 'Azure OpenAI API Key',
  //   description: 'Enter your Azure OpenAI API Key',
  //   defaultValue: '',
  // })
  // .addTextInput({
  //   path: 'endpoint',
  //   name: 'Azure OpenAI Endpoint',
  //   description: 'Enter your Azure OpenAI Endpoint URL',
  //   defaultValue: '',
  // })
  // .addTextInput({
  //   path: 'deploymentName',
  //   name: 'Azure OpenAI deployed model name',
  //   description: 'Enter your Azure OpenAI deployed model name',
  //   defaultValue: '',
  // });
});
