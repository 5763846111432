import { css } from '@emotion/css';

/**
 * Styles
 */

export const Styles = () => {
    return {
        wrapper: css`
        display: flex;
        flex-direction: column;
        `,
        options: css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        gap: 10px;
        `,
        selectInput: css`
        max-width: 130px;
        `,
        checkbox: css`
        margin-right: 10px;
        `,
        outputText: css`
        width: 100%;
        flex: 1;
        overflow-y: auto;

        h3 {
            font-size: 1em;
        }

        ul {
            margin-bottom: 10px;
        }
        `,
        svg: css`
        position: absolute;
        top: 0;
        left: 0;
        `,
        textBox: css`
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 10px;
        `,
        override: css`
      display: block;
      margin: 0 auto;
      border-color: red;
      `,
    };
};
