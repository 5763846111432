import React, { useState } from 'react';
// import axios from 'axios';
import { openai } from '@grafana/llm';
import { PanelProps } from '@grafana/data';
import { PanelOptions } from 'types';
import { Card, CustomScrollbar, useStyles2, Spinner, ConfirmButton, RenderUserContentAsHTML } from '@grafana/ui';
// import ReactMarkdown from 'react-markdown';
// import DOMPurify from 'dompurify';
// import remarkGfm from 'remark-gfm'
import { css, cx } from '@emotion/css';
import ol_depen from './ol_dependencies.json';
import { Styles } from '../styles';

interface Props extends PanelProps<PanelOptions> {}

type Role = 'system' | 'user' | 'assistant' | 'function';
interface Message {
  role: Role;
  content: string;
  name?: string;
  function_call?: Object;
}
// load the dependency data
const ol_depen_data = JSON.stringify(ol_depen, null, 2);
// console.log(ol_depen_data);
//const systemMsg = `You are a helpful assistant in Grafana interpreting Loki logging data and giving responses in HTML format. Your objective is to Analyze error logs from multiple interdependent services and provide insights based on the logging data obtained from Loki. Use the provided service dependencies to identify potential error sources and suggest further investigations.` 
const systemMsg = `You are a helpful assistant in Grafana interpreting Loki logging data and giving responses in a structured HTML format. ` 

const promptMsg = `
Your objective is to Analyze error logs from multiple interdependent services and provide insights based on the logging data obtained from Loki. Use the provided service dependencies to identify potential error sources and suggest further investigations.

Context: 
Loki logs and metrics are collected from over 15 services, each with dependencies on others to function correctly. Below are the dependencies for each service:

- integration: CareISAPI, CareISAuth, NHSFhirPatient, NHSOauth2, NHSFhir, directorySpineservices, integrationPDS, spine2, auth-proxy, Test1-D4V3N, CareISClassicApi, digiauth, ehr
- auditor: terminology, auth-proxy, demographics
- abac: auth-proxy, demographics, keycloak
- ehr: abac, ehrscapeTerm, SCPA-UAT, auth-proxy, demographics
- store: auth-proxy
- patient-data-export: demographics, auth-proxy
- user-management: auth-proxy, demographics, keycloak
- patient-lookup: auth-proxy, ehr, demographics
- share: NHSAppREdirect, demographics, auth-proxy, keycloak, embedded, ds2-security, NHSlogin, NHSOauth2
- dashboard: ehr, demographics, auditor, auth-proxy
- ods-importer: demographics, directorySpineservices, user-management, auth-proxy
- store-proxy: auth-proxy, store
- document-generator: auth-proxy, ehr, patient-document, demographics, store, integration, SCPA-UAT
- settings: auth-proxy
- backup_20231107: NHSAppREdirect, demographics, auth-proxy, keycloak, embedded, ds2-security, NHSlogin
- demographics: demographics, auth-proxy, abac
- auth-proxy: keycloak, auth-proxy, uat2KC
- report-generator: ehr, demographics, auth-proxy
- auth-broker: keycloak, uat2KC, embedded, ds2-security, demographics, auth-proxy, ds2-auth

Log Data Query:  
Error logs are fetched from Loki using the LogQL query:
\`\`\`
{level="ERROR"} | json
\`\`\`
The logs are structured as \`{queryTitle: [queryContent]}\`, where \`queryTitle\` is the key and \`[queryContent]\` contains the error data.

Report Requirements:

1. Timespan and System Status:
   - Begin by stating the timespan of the logs in date-time format.
   - Provide a system status summary:
     - If errors are found, indicate the number of apps with error reports (e.g., "There were X apps with error reports in the system.").
     - If no errors are found, state positively (e.g., "There were no errors to report. Great!").

2. Error Summary:
   - For each app, summarize:
     - Error type counts per app.
     - Error message counts per error type and app.
   - Present these summaries in tables with horizontal dividers and line breaks for clarity. Let the table columns be : App, Error Type, Count. Let the rows be sorted by the Count in descending order.

3. Brief Insights:
   - Provide a concise interpretation of the summarizing tables, highlighting the most frequent errors.

4. Dependency Analysis and Recommendations:
   - Based on the service dependencies, suggest which apps should be further inspected as possible origins of errors.
   - Provide up to three LogQL queries that would be a good starting point for further investigation.

5. Key Insights:
   - Keep the report focused on key findings without excessive detail.

6. whole response has to be in neatly structured HTML format.
`

const reshapeData = (data: any) => {
  console.log('Data received:', data);
  let queryCounter = 1; // Initialize query counter
  const allTimestamps: number[] = []; // Array to store all timestamps

  const queryFrames = data.series.map((series: any) => {
    // Title for each frame
    const queryTitle = `Query no. ${queryCounter++}`;
  
    // Construct query content
    const queryContent: { [key: string]: any }[] = [];
    const numRows = series.fields[0].values.length; // Assuming all fields have the same number of values
    let totalCharCount = 0; // Initialize character count
    
    for (let i = 0; i < numRows; i++) {
      const rowObject: { [key: string]: any } = {};
      series.fields.forEach((field: any) => {
        let value = field.values[i];
        // Convert timestamp fields to human readable format
        if (field.name.toLowerCase().includes('time') && typeof value === 'number') {
          allTimestamps.push(value); // Collect timestamp
          value = new Date(value).toLocaleString();
        }
        // From labels field remove "error_stack_trace" field if present
        if (field.name === 'labels' && typeof value === 'object') {
          // console.log('Field with name labels found:', field.name, '. Content is of type:', typeof value);
          try {
            if (value['error_stack_trace']) {
              delete value['error_stack_trace'];
            } 
          } catch (e) {
            console.error('Failed to parse JSON in labels field:', e);
          }
        } 
        // rowObject[field.name] = value;
        // Include only the specified fields
        if (['labels', 'Time', 'tsNs', 'id'].includes(field.name)) {
          rowObject[field.name] = value;
        }
      });

      // Convert rowObject to string to count characters
      const rowObjectStr = JSON.stringify(rowObject);
      totalCharCount += rowObjectStr.length;

      // Check if total character count exceeds 400,000
      if (totalCharCount > 400000) {
        console.log('Character count exceeded 400,000. Stopping parsing.');
        break;
      }
      queryContent.push(rowObject);
    }
  
    // Return an object with frameTitle as key and frameContent as value
    return { [queryTitle]: queryContent };
  });

  // Calculate timespan
  const earliestTimestamp = Math.min(...allTimestamps);
  const latestTimestamp = Math.max(...allTimestamps);
  const timeSpan = {
    start: new Date(earliestTimestamp).toLocaleString(),
    end: new Date(latestTimestamp).toLocaleString()
  };
  return {
    queryFrames,
    timeSpan
  };
};

const LLMPanel: React.FC<Props> = ({ options, data, width, height, fieldConfig, id }): JSX.Element => {

  const styles = useStyles2(Styles);

  const [interpretation, setInterpretation] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [userInput, setUserInput] = useState('');
  const [chatHistory, setChatHistory] = useState<{ role: string, content: string }[]>([]);
  
  // const [buttonText, setButtonText] = useState('Analyse');
  // const [buttonEnabled, setButtonEnabled] = useState(true);
  // const [selectedOption, setSelectedOption] = useState('');
  // const [promptMsg, setPromptMsg] = useState(analysisOptions.Summary);

  // const handleOptionChange = (event: any) => {
  //   const selected = event.target.value;
  //   setSelectedOption(selected);
  //   setPromptMsg(analysisOptions[selected]);
  // };

  const onButtonClick = async () => {
    console.log('Button clicked');
    
    if (!data.series.length) {
      setError('No data available');
      return;
    }

    console.log('Number of data queries:', data.series.length);

    // reshape the data into a JSON structure, convert the unix epochs in Time field to human readable date-time format
    // and remove "error.stack_trace" from the "Line" field field of Loki log data
    const { queryFrames, timeSpan } = reshapeData(data);
    const timeSpanText = JSON.stringify(timeSpan);
    // console.log('Time span object:', timeSpan, '\nTime span text:', timeSpanText);

    // Combine all queryFrames into a single structure
    const metricStruct = queryFrames.reduce((acc: any, frame: any) => ({ ...acc, ...frame }), {});
    // console.log(JSON.stringify(metricStruct, null, 2));
    const metricText = JSON.stringify(metricStruct, null, 2);
    console.log(`Number of characters in metricText: ${metricText.length}`);

    setIsLoading(true);

    try {
      // Log the system message
      console.log('System message:', systemMsg);

      const enabled = await openai.enabled();
      if (!enabled) {
        console.error('Azure OpenAI API is not enabled');
        setError('Azure OpenAI API is not enabled');
      }

      // Log before posting to the OpenAI API
      console.log('Sending chatCompletions request to the Azure OpenAI API');
      console.log('Prompt:', `${promptMsg}\nTime span: ${timeSpanText}\nLog data:\n${metricText}`);
      // Post to the Azure OpenAI API to get the completions
      const initialResponse = await openai.chatCompletions({
        model: 'gpt-4o',
        temperature: 0,
        messages: [
          { role: 'system', content: systemMsg },
          { role: 'user', content: `${promptMsg}\nTime span for investigation: ${timeSpanText}\nLog data from Loki:\n${metricText}\n\n\n Output HTML structured report. Output should start with: <h1> System Status Report </h1> Make sure there is enough space/newlines between different sections of the report.` },
        ],
      });
      console.log('Response received:', initialResponse);
      
      // Extract the response text from the initial response and replace newline characters with empty strings
      const initialResponseText = initialResponse.choices[0].message.content.replace(/\n/g, '');
      // Add system message to chat history
      const newChatHistory = [...chatHistory, { role: 'system', content: systemMsg }];
      // Add user input to chat history
      newChatHistory.push({ role: 'user', content: `${promptMsg}\nLog data from Loki:\n${metricText}` });
      // Add assistant response to chat history
      newChatHistory.push({ role: 'assistant', content: initialResponseText });
      setChatHistory(newChatHistory);
      setInterpretation(initialResponseText);
      // Log the chat history
      // console.log('Chat history before user input:', newChatHistory);
    } catch (error) {
      console.error('Error fetching response from Azure OpenAI API:', error);
      setError('Failed to fetch response from Azure OpenAI API');
      const err_display = `${error} \nIf the error mentions exceeding the rate limit please consider 
                                narrowing the time range. Additionally, with Loki data consider reducing the 
                                prompt load by droping some of the data fields, e.g. the stack trace field by adding
                                json processor and field drop expressions in the Loki query like so:
                                {app="abac"} |= \`WARN\` | json | drop error_stack_trace`;
      setInterpretation(err_display);
      
    } finally {
      setIsLoading(false);
    }

  };

  const handleUserInputSubmit = async () => {
    if (!userInput.trim()) return;

    setIsLoading(true);
    // const newUserMessage = userInput.trim();
    // const newChatHistory = [...chatHistory, `User: ${newUserMessage}`];
    // Add user input to chat history
    const newChatHistory = [...chatHistory, { role: 'user', content: userInput }];
    // console.log('Chat history with added user input:', newChatHistory);
    // // Ensure chatHistory does not exceed 400k characters
    // while (newChatHistory.join(' ').length > 400000) {
    //   // newChatHistory.shift();
    //   if (newChatHistory.length > 2) {
    //     newChatHistory.splice(2, 1); // Remove elements after the initial prompt and response
    //   } else {
    //     break;
    //   }
    // }

    // Limit chat history to 450,000 characters
    // if the limit is exceeded and there are only 3 messages, remove the last message
    let totalCharacters = newChatHistory.reduce((acc, msg) => acc + msg.content.length, 0);
    while (totalCharacters > 450000) {
      if (newChatHistory.length > 4) {
        totalCharacters -= newChatHistory[3].content.length;
        newChatHistory.splice(3, 1);
      } else if (newChatHistory.length === 4) {
        totalCharacters -= newChatHistory[2].content.length;
        newChatHistory.splice(2, 1);
      } else {
        break;
      }
    }
    // Log the chat history
    console.log('Chat history going into request:', newChatHistory);
    
    // Convert newChatHistory to Message format
    const formattedChatHistory: Message[] = newChatHistory.map(msg => ({
      role: msg.role as Role,
      content: msg.content,
    }));

    try {
      const userResponse = await openai.chatCompletions({
        model: 'gpt-4o',
        temperature: 0,
        // messages: [
        //   { role: 'system', content: systemMsg },
        //   { role: 'user', content: newUserMessage },
        // ],
        messages: formattedChatHistory,
      });

      // Extract the response text from the user response and replace newline characters with empty strings
      const userResponseText = userResponse.choices[0].message.content.replace(/\n/g, '').replace(/^(```|```html|html)|(```html|html )|(```|```html)$/g, '');
      // Add assistant response to chat history
      newChatHistory.push({ role: 'assistant', content: userResponseText });
      setChatHistory(newChatHistory);
      //setInterpretation((prevResponse) => 
      //  (prevResponse 
      //    ? `${prevResponse}<br><br>You: ${userInput}<br><br>${userResponseText}` 
      //    : `You: ${userInput}<br><br>${userResponseText}`
      //  ));
      setInterpretation((prevResponse) => `<html>${userResponseText}</html>`);
      
      
      // console.log('Formatted interpretation:', formattedInterpretation);
    } catch (error) {
      console.error('Error fetching response from Azure OpenAI API:', error);
      setInterpretation((prevResponse) => 
        (prevResponse 
          ? `${prevResponse}<br><br>You: ${userInput}<br><br>ERROR: Failed to fetch response from Azure OpenAI API`
          : `You: ${userInput}<br><br>ERROR: Failed to fetch response from Azure OpenAI API`));
    } finally {
      setIsLoading(false);
      setUserInput('');
    }
  };
  
  const formattedInterpretation = (interpretation ?? '')
    .replace(/^(```|```html|html)|(```html|html )|(```|```html)$/g, '')
    .split('<br>')
    .map((line, index) => (line.startsWith('You:') ? `<strong style="color: magenta;">${line}</strong>` : line))
    .join('<br>');

  return (
   
    <div 
      className={cx(
        styles.wrapper, 
        css`
          width: ${width}px;
          height: ${height}px;
          padding: 10px;
        `
      )}
    >
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <div className={cx(styles.options)}>
        <button onClick={onButtonClick} disabled={isLoading} style={{ padding: '10px 20px' }}>
          {isLoading ? 'Initializing chat assistant ...' : 'Initialize chat assistant'}
        </button>
        {isLoading && <Spinner className={cx(styles.override)} size='xl'/>}
      </div>
      
      {interpretation && (
        <>
          <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
          <input
            type="text"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            placeholder="Inquire about system status..."
            onKeyPress={(e) => {
              if (e.key === 'Enter') handleUserInputSubmit();
            }}
            disabled={isLoading}
            style={{ flex: 1, height: '40px', marginRight: '10px' }}
          />
          <ConfirmButton onConfirm={handleUserInputSubmit} disabled={isLoading} size='md'>
            {isLoading ? '...' : 'Send'}
          </ConfirmButton>
        </div>
          <Card style={{ height: 'auto', maxHeight: 'calc(100% - 40px)', overflow: 'hidden', position: 'relative', marginTop: '10px' }}>
            {isLoading && <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(255, 255, 255, 0.4)', zIndex: 1 }}><Spinner/></div>}
            <CustomScrollbar autoHeightMin="200px" autoHeightMax="100%">
              <div style={{ padding: '10px', opacity: isLoading ? 0.5 : 1 }}>
                {/* <ReactMarkdown className={cx(styles.outputText)} remarkPlugins={[remarkGfm]}>
                  {formattedInterpretation}
                </ReactMarkdown> */}
                <RenderUserContentAsHTML content={formattedInterpretation} />
              </div>
            </CustomScrollbar>
          </Card>
        </>
      )}
    </div>
  );
};

export { LLMPanel };
